import React from "react";

import { Layout } from "../components/Layout";
import { Page } from "../components/Page";
import { Title } from "../components/Title";
import { Quote } from "../components/Quote";
import { Row, Column } from "../style";

export default function MappingPage() {
  return (
    <Layout>
      <Page
        pageTitle="Mapping"
        heroHeadline="VISUALIZING DATA FOR A HEALTHIER PLANET"
        heroTextline="Taking better care of People, Planet, and the Places we live"
        pageDescription="Takiwā Mapping enables large amounts of complex data, including geographic information, to be combined, analysed and presented in visually powerful ways."
        backgroundUrl={
          "https://storage.googleapis.com/takiwa-gatsby-landing-images/hero_bg_compass.jpg"
        }
        backgroundPosition="120% 120%"
      >
        <Title>Mapping</Title>
        <Row>
          <Column span="4">
            <p>
              Takiwā Mapping enables you to connect people, locations, and your
              data. Work with smart, data-driven systems and intuitive analysis
              tools, delivering incredible results.
            </p>
          </Column>
          <Column span="8">
            <img
              src="https://storage.googleapis.com/takiwa-gatsby-landing-images/mapping1.png"
              alt="Takiwa map screenshot"
            />
          </Column>
        </Row>
        <Row>
          <Column span="4">
            <p>
              Takiwā Mapping enables large amounts of complex data, including
              geographic information, to be combined, analysed and presented in
              visually powerful ways.
            </p>
          </Column>
          <Column span="8">
            <img
              src="https://storage.googleapis.com/takiwa-gatsby-landing-images/mapping2.png"
              alt="Takiwa map screenshot"
            />
          </Column>
        </Row>
        <Quote>Mapping data and analysis for everyone.</Quote>
      </Page>
    </Layout>

  );
}
